import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  ref: "parameterDiv",
  class: "scrollable"
}
const _hoisted_2 = { class: "p-d-flex p-jc-between p-ai-center sbmlType" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_TemplateId = _resolveComponent("TemplateId")!
  const _component_Column = _resolveComponent("Column")!
  const _component_BooleanSymbol = _resolveComponent("BooleanSymbol")!
  const _component_Katex = _resolveComponent("Katex")!
  const _component_TemplateUnits = _resolveComponent("TemplateUnits")!
  const _component_DataTable = _resolveComponent("DataTable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.objects.length > 0)
      ? (_openBlock(), _createBlock(_component_DataTable, {
          key: 0,
          value: _ctx.objects,
          paginator: _ctx.objects.length > 10,
          rows: 10,
          rowsPerPageOptions: [10, 25, 50],
          filters: _ctx.filters,
          "onUpdate:filters": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filters) = $event)),
          filterDisplay: "menu",
          sortMode: "multiple",
          style: {"font-size":"12px"},
          class: "p-datatable-sbml",
          globalFilterFields: ['global', 'searchUtilField'],
          responsiveLayout: "scroll",
          rowHover: true,
          onRowClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.openComponent($event.data.pk)))
        }, {
          header: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_font_awesome_icon, {
                icon: `${_ctx.icon}`,
                "fixed-width": true,
                class: "p-mr-1"
              }, null, 8, ["icon"]),
              _createTextVNode(" " + _toDisplayString(_ctx.header), 1)
            ])
          ]),
          default: _withCtx(() => [
            _createVNode(_component_Column, {
              sortable: "",
              class: "column",
              field: "id",
              header: "id"
            }, {
              body: _withCtx((props) => [
                _createVNode(_component_TemplateId, {
                  data: props.data
                }, null, 8, ["data"])
              ]),
              _: 1
            }),
            _createVNode(_component_Column, {
              sortable: "",
              class: "column",
              field: "name",
              header: "name"
            }),
            _createVNode(_component_Column, {
              sortable: "",
              class: "column",
              field: "constant",
              header: "constant"
            }, {
              body: _withCtx((props) => [
                _createVNode(_component_BooleanSymbol, {
                  value: props.data.constant
                }, null, 8, ["value"])
              ]),
              _: 1
            }),
            _createVNode(_component_Column, {
              sortable: "",
              class: "column",
              field: "value",
              header: "value"
            }),
            _createVNode(_component_Column, {
              sortable: "",
              class: "column",
              field: "units",
              header: "units"
            }, {
              body: _withCtx((props) => [
                (props.data.units != null)
                  ? (_openBlock(), _createBlock(_component_Katex, {
                      key: 0,
                      mathStr: props.data.units,
                      class: "katex_unit"
                    }, null, 8, ["mathStr"]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }),
            _createVNode(_component_Column, {
              sortable: "",
              class: "column",
              field: "derivedUnits",
              header: "derivedUnits"
            }, {
              body: _withCtx((props) => [
                _createVNode(_component_TemplateUnits, {
                  units: props.data.derivedUnits
                }, null, 8, ["units"])
              ]),
              _: 1
            }),
            _createVNode(_component_Column, {
              sortable: "",
              class: "column",
              field: "assignment",
              header: "assignment"
            }, {
              body: _withCtx((props) => [
                (props.data.assignment != null)
                  ? (_openBlock(), _createBlock(_component_Katex, {
                      key: 0,
                      mathStr: props.data.assignment.math
                    }, null, 8, ["mathStr"]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["value", "paginator", "filters"]))
      : _createCommentVNode("", true)
  ], 512))
}