import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-43fb7d48"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "symbol" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.value)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
          _createVNode(_component_font_awesome_icon, {
            icon: "check-square",
            title: "true",
            style: {"color":"black","font-size":"13px"}
          })
        ]))
      : (!_ctx.value)
        ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
            _createVNode(_component_font_awesome_icon, {
              icon: "square",
              title: "false",
              style: {"color":"darkgrey","font-size":"13px"}
            })
          ]))
        : (_openBlock(), _createElementBlock("span", _hoisted_4, [
            _createVNode(_component_font_awesome_icon, {
              icon: "ban black",
              style: {"color":"black","font-size":"13px"}
            })
          ]))
  ]))
}