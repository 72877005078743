
import { defineComponent } from "@vue/runtime-core";

import About from "@/components/layout/About.vue";
import Examples from "@/components/layout/Examples.vue";
import SubmitOptions from "@/components/layout/SubmitOptions.vue";

export default defineComponent({
    components: {
        Examples,
        SubmitOptions,
        About,
    },
});
