
import { defineComponent } from "vue";

import CVTermBadge from "@/components/sbmlmisc/CVTermBadge.vue";
import CodeContainer from "@/components/layout/CodeContainer.vue";

export default defineComponent({
    components: {
        CVTermBadge,
        CodeContainer,
    },

    props: {
        info: {
            type: Object,
        },
    },
});
