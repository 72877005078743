
import { defineComponent } from "@vue/runtime-core";
import store from "@/store/index";

export default defineComponent({
    data() {
        return {
            nodes: JSON.parse(JSON.stringify(store.state.OMEXTree)),
            expandedKeys: {},
        };
    },

    mounted() {
        //console.log(JSON.parse(JSON.stringify(store.state.OMEXTree)));
        this.highlightPath();
    },

    computed: {
        activePath() {
            const currentDocumentPath = JSON.parse(
                JSON.stringify(store.state.currentDocumentLocation)
            );
            const tokens = currentDocumentPath.split("/");
            return tokens;
        },
    },

    methods: {
        changeReport(location: string) {
            const requiredContext = JSON.parse(JSON.stringify(store.state.contexts))[
                location
            ];
            store.dispatch("updateReportStatesAndFollowUp", requiredContext);
            store.dispatch("updateCurrentDocumentLocation", location);
            this.highlightPath();
        },

        highlightPath() {
            this.expandedKeys = {};
            const tokens = this.activePath;
            for (let i in tokens) {
                this.expandedKeys[tokens[i]] = true;
            }
        },
    },
});
