
import store from "@/store/index";
import icons from "@/data/fontAwesome";
import colorScheme from "@/data/colorScheme";
import TYPES from "@/data/sbmlComponents";
import { defineComponent } from "@vue/runtime-core";

import UncertaintyBadge from "@/components/sbmlmisc/UncertaintyBadge.vue";

/**
 * Component to define display of SBase information.
 */
export default defineComponent({
    components: {
        UncertaintyBadge,
    },

    props: {
        info: {
            type: Object,
            default: TYPES.SBase,
        },
        math: {
            type: String,
            default: "",
        },
    },

    methods: {
        openComponent(pk: string): void {
            store.dispatch("pushToHistoryStack", pk);
        },
    },

    computed: {
        color(): string {
            return colorScheme.componentColor[this.info.sbmlType];
        },

        icon(): string {
            return icons.icons[this.info.sbmlType];
        },
    },
});
