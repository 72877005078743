import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-cf7c9d5e"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  ref: "detailContainer",
  class: "detail-container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SBase = _resolveComponent("SBase")!
  const _component_component_specific_details = _resolveComponent("component-specific-details")!
  const _component_additional_data = _resolveComponent("additional-data")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_SBase, { info: _ctx.info }, null, 8, ["info"]),
    _createVNode(_component_component_specific_details, {
      info: _ctx.info,
      sbmlType: _ctx.info.sbmlType
    }, null, 8, ["info", "sbmlType"]),
    _createVNode(_component_additional_data, { info: _ctx.info }, null, 8, ["info"])
  ], 512))
}