
import { defineComponent } from "vue";
import store from "@/store";

/**
 * Navbar component for providing main links in the application
 */
export default defineComponent({
    data() {
        return {
            items: [
                {
                    label: "Home",
                    icon: "pi pi-fw pi-home",
                    to: "/",
                },
                {
                    label: "Examples",
                    icon: "pi pi-fw pi-list",
                    to: "/examples",
                },
                {
                    label: "About",
                    icon: "pi pi-fw pi-info-circle",
                    to: "/about",
                },

                {
                    label: "Report issue",
                    icon: "pi pi-fw pi-pencil",
                    url: "https://github.com/matthiaskoenig/sbmlutils/issues/new/choose",
                },
                {
                    label: "Source",
                    icon: "pi pi-fw pi-github",
                    url: "https://github.com/matthiaskoenig/sbmlutils",
                },
            ],
        };
    },
});
