
import TYPES from "@/data/sbmlComponents";
import { defineComponent } from "@vue/runtime-core";

/* Components */
import SBMLDocument from "@/components/sbml/SBMLDocument.vue";
import Model from "@/components/sbml/Model.vue";
import Port from "@/components/sbml/Port.vue";
import FunctionDefinition from "@/components/sbml/FunctionDefinition.vue";
import UnitDefinition from "@/components/sbml/UnitDefinition.vue";
import Compartment from "@/components/sbml/Compartment.vue";
import Species from "@/components/sbml/Species.vue";
import Parameter from "@/components/sbml/Parameter.vue";
import Reaction from "@/components/sbml/Reaction.vue";
import AssignmentRule from "@/components/sbml/AssignmentRule.vue";
import RateRule from "@/components/sbml/RateRule.vue";
import AlgebraicRule from "@/components/sbml/AlgebraicRule.vue";
import Constraint from "@/components/sbml/Constraint.vue";
import Event from "@/components/sbml/Event.vue";
import Objective from "@/components/sbml/Objective.vue";
import GeneProduct from "@/components/sbml/GeneProduct.vue";
import InitialAssignment from "@/components/sbml/InitialAssignment.vue";

/*
 * Component to inject a specific SBML component as required by in the parent component.
 */
export default defineComponent({
    props: {
        sbmlType: {
            type: String,
            default: "SBMLDocument",
        },
        info: {
            type: Object,
            default: TYPES.SBMLDocument,
        },
    },

    components: {
        InitialAssignment,
        SBMLDocument,
        Model,
        Port,
        FunctionDefinition,
        UnitDefinition,
        Compartment,
        Species,
        Parameter,
        Reaction,
        AssignmentRule,
        RateRule,
        AlgebraicRule,
        Constraint,
        Event,
        Objective,
        GeneProduct,
    },
});
