import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_katex = _resolveComponent("katex")!

  return (_ctx.units != null)
    ? (_openBlock(), _createBlock(_component_katex, {
        key: 0,
        mathStr: _ctx.units,
        class: "katex_unit"
      }, null, 8, ["mathStr"]))
    : _createCommentVNode("", true)
}