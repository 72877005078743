
import xmlFormatter from "xml-formatter";
import { defineComponent } from "vue";

/**
 * Component for rendering XML/SBML.
 */
export default defineComponent({
    props: {
        info: {
            type: Object,
            required: false,
        },
    },

    data(): Record<string, unknown> {
        return {
            visible: false,
            language: 0, // 0 for XML, 1 for JSON
        };
    },

    methods: {
        toggleToXML(): void {
            if (this.language === 1) {
                this.visible = true;
            } else {
                this.visible = !this.visible;
            }
            this.language = 0;
        },

        toggleToJSON(): void {
            if (this.language === 0) {
                this.visible = true;
            } else {
                this.visible = !this.visible;
            }
            this.language = 1;
        },
    },

    computed: {
        /**
         * Formats and returns the raw XML string passed from the parent into
         * pretty-printed form.
         */
        formattedXML(): string {
            let formattedXML: string;
            formattedXML = xmlFormatter(
                (this.info as unknown as Record<string, unknown>)
                    .xml as unknown as string
            );
            return formattedXML;
        },
    },
});
