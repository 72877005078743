
import TYPES from "@/data/sbmlComponents";
import { defineComponent } from "@vue/runtime-core";

import Katex from "@/components/layout/Katex.vue";
import Boolean from "@/components/layout/BooleanSymbol.vue";

/**
 * Component to define display of Event objects.
 */
export default defineComponent({
    components: {
        Katex,
        Boolean,
    },

    props: {
        info: {
            type: Object,
            default: TYPES.Event,
        },
    },
});
