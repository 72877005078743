import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-5a86a86c"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  id: "documentDiv"
}
const _hoisted_2 = {
  key: 1,
  id: "modelDiv"
}
const _hoisted_3 = {
  key: 2,
  id: "portDiv"
}
const _hoisted_4 = {
  key: 3,
  id: "functionDefinitionDiv"
}
const _hoisted_5 = {
  key: 4,
  id: "unitDefinitionDiv"
}
const _hoisted_6 = {
  key: 5,
  id: "compartmentDiv"
}
const _hoisted_7 = {
  key: 6,
  id: "speciesDiv"
}
const _hoisted_8 = {
  key: 7,
  id: "parameterDiv"
}
const _hoisted_9 = {
  key: 8,
  id: "reactionDiv"
}
const _hoisted_10 = {
  key: 9,
  id: "assignmentRuleDiv"
}
const _hoisted_11 = {
  key: 10,
  id: "initialAssignmentDiv"
}
const _hoisted_12 = {
  key: 11,
  id: "rateRuleDiv"
}
const _hoisted_13 = {
  key: 12,
  id: "algebraicRuleDiv"
}
const _hoisted_14 = {
  key: 13,
  id: "constraintDiv"
}
const _hoisted_15 = {
  key: 14,
  id: "eventDiv"
}
const _hoisted_16 = {
  key: 15,
  id: "objectiveDiv"
}
const _hoisted_17 = {
  key: 16,
  id: "geneProductDiv"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SBMLDocument = _resolveComponent("SBMLDocument")!
  const _component_model = _resolveComponent("model")!
  const _component_port = _resolveComponent("port")!
  const _component_function_definition = _resolveComponent("function-definition")!
  const _component_unit_definition = _resolveComponent("unit-definition")!
  const _component_compartment = _resolveComponent("compartment")!
  const _component_species = _resolveComponent("species")!
  const _component_parameter = _resolveComponent("parameter")!
  const _component_reaction = _resolveComponent("reaction")!
  const _component_assignment_rule = _resolveComponent("assignment-rule")!
  const _component_initial_assignment = _resolveComponent("initial-assignment")!
  const _component_rate_rule = _resolveComponent("rate-rule")!
  const _component_algebraic_rule = _resolveComponent("algebraic-rule")!
  const _component_constraint = _resolveComponent("constraint")!
  const _component_event = _resolveComponent("event")!
  const _component_objective = _resolveComponent("objective")!
  const _component_gene_product = _resolveComponent("gene-product")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.sbmlType === 'SBMLDocument')
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_SBMLDocument, { info: _ctx.info }, null, 8, ["info"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.sbmlType === 'Model')
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_model, { info: _ctx.info }, null, 8, ["info"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.sbmlType === 'Port')
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_port, { info: _ctx.info }, null, 8, ["info"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.sbmlType === 'FunctionDefinition')
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_function_definition, { info: _ctx.info }, null, 8, ["info"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.sbmlType === 'UnitDefinition')
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createVNode(_component_unit_definition, { info: _ctx.info }, null, 8, ["info"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.sbmlType === 'Compartment')
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createVNode(_component_compartment, { info: _ctx.info }, null, 8, ["info"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.sbmlType === 'Species')
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _createVNode(_component_species, { info: _ctx.info }, null, 8, ["info"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.sbmlType === 'Parameter')
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
          _createVNode(_component_parameter, { info: _ctx.info }, null, 8, ["info"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.sbmlType === 'Reaction')
      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
          _createVNode(_component_reaction, { info: _ctx.info }, null, 8, ["info"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.sbmlType === 'AssignmentRule')
      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
          _createVNode(_component_assignment_rule, { info: _ctx.info }, null, 8, ["info"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.sbmlType === 'InitialAssignment')
      ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
          _createVNode(_component_initial_assignment, { info: _ctx.info }, null, 8, ["info"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.sbmlType === 'RateRule')
      ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
          _createVNode(_component_rate_rule, { info: _ctx.info }, null, 8, ["info"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.sbmlType === 'AlgebraicRule')
      ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
          _createVNode(_component_algebraic_rule, { info: _ctx.info }, null, 8, ["info"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.sbmlType === 'Constraint')
      ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
          _createVNode(_component_constraint, { info: _ctx.info }, null, 8, ["info"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.sbmlType === 'Event')
      ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
          _createVNode(_component_event, { info: _ctx.info }, null, 8, ["info"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.sbmlType === 'Objective')
      ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
          _createVNode(_component_objective, { info: _ctx.info }, null, 8, ["info"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.sbmlType === 'GeneProduct')
      ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
          _createVNode(_component_gene_product, { info: _ctx.info }, null, 8, ["info"])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}