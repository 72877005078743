
import TYPES from "@/data/sbmlComponents";
import { defineComponent } from "@vue/runtime-core";

/**
 * Component to define display of GeneProduct objects.
 */
export default defineComponent({
    props: {
        info: {
            type: Object,
            default: TYPES.GeneProduct,
        },
    },
});
