import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-027586aa"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-d-flex p-mt-2" }
const _hoisted_2 = {
  key: 0,
  class: "p-card"
}
const _hoisted_3 = {
  key: 0,
  class: "p-d-flex url"
}
const _hoisted_4 = { href: "{{ parameter.definitionURL }}" }
const _hoisted_5 = {
  key: 1,
  class: "math"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Tag = _resolveComponent("Tag")!
  const _component_katex = _resolveComponent("katex")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_Tag, {
        value: `type: ${_ctx.parameter.type}`,
        severity: "warning",
        class: "qualifier"
      }, null, 8, ["value"]),
      _createVNode(_component_Tag, {
        value: `value: ${_ctx.parameter.value}`,
        severity: "info",
        class: "resource"
      }, null, 8, ["value"])
    ]),
    (
            _ctx.parameter != null &&
            _ctx.parameter.definitionURL != null &&
            _ctx.parameter.math != null
        )
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_ctx.parameter.definitionURL != null)
            ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
                _createTextVNode("definitionURL: "),
                _createElementVNode("a", _hoisted_4, _toDisplayString(_ctx.parameter.definitionURL), 1)
              ]))
            : _createCommentVNode("", true),
          (_ctx.parameter.math != null)
            ? (_openBlock(), _createElementBlock("span", _hoisted_5, [
                _createTextVNode(" math: "),
                _createVNode(_component_katex, {
                  "math-str": _ctx.parameter.math
                }, null, 8, ["math-str"])
              ]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ], 64))
}