
import { defineComponent } from "@vue/runtime-core";
import Katex from "@/components/layout/Katex.vue";
export default defineComponent({
    components: {
        Katex,
    },
    props: {
        units: {
            type: String,
        },
    },
});
