import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProgressBar = _resolveComponent("ProgressBar")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    header: _ctx.message,
    footer: " ",
    visible: _ctx.loading,
    "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.loading) = $event)),
    style: { width: '40vw' },
    breakpoints: { '960px': '75vw', '640px': '100vw' },
    modal: true,
    closable: false
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ProgressBar, {
        class: "p-my-5",
        mode: "indeterminate",
        style: {"height":"0.5em"}
      }),
      _createTextVNode(" This may take a few seconds. Please be patient. ")
    ]),
    _: 1
  }, 8, ["header", "visible"]))
}