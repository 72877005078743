
import store, { VUE_APP_FRONTENDURL } from "@/store/index";
import { defineComponent } from "vue";

import Loading from "@/components/layout/Loading.vue";
/**
 * Component to upload an SBML file to generate report.
 */
export default defineComponent({
    components: {
        Loading,
    },

    data() {
        return {
            modelValue: "",
        };
    },

    methods: {
        updateURL(event): void {
            this.modelValue = event.target.value;
        },
        async submitForm(): Promise<void> {
            store.dispatch("fetchReportUsingURL", this.modelValue);
        },
    },

    computed: {
        loading(): boolean {
            return store.state.fileLoading;
        },
        frontend_url(): string {
            return VUE_APP_FRONTENDURL;
        },
        example_url(): string {
            return (
                VUE_APP_FRONTENDURL +
                "/model_url?url=https://raw.githubusercontent.com/matthiaskoenig/sbmlutils/develop/src/sbmlutils/resources/models/glucose/Hepatic_glucose_3.xml"
            );
        },
    },
});
