
import { defineComponent } from "vue";
import { fetchAdditionalInfo } from "@/helpers/additionalInfoUtils";

export default defineComponent({
    props: {
        qualifier: {
            type: String,
            default: "",
        },
        resource: {
            type: String,
            default: "",
        },
    },

    data() {
        return {
            addInfo: {},
        };
    },

    created() {
        fetchAdditionalInfo(this.resource).then((res) => {
            this.addInfo = res as Record<string, unknown>;
        });
    },
});
