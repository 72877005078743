
import store from "@/store/index";
import { defineComponent } from "vue";

import DetailContainer from "@/components/layout/DetailContainer.vue";
import TablesContainer from "@/components/layout/TablesContainer.vue";
import ComponentMenu from "@/components/layout/ComponentMenu.vue";
//import DocumentMenu from "@/components/layout/DocumentMenu.vue";
import OMEXTree from "@/components/layout/OMEXTree.vue";

/**
 * Component to hold all components to show the generated report.
 */
export default defineComponent({
    data() {
        return {
            searchQuery: "",
        };
    },

    components: {
        ComponentMenu,
        //DocumentMenu,
        DetailContainer,
        TablesContainer,
        OMEXTree,
    },

    computed: {
        coreComponents(): Array<Record<string, unknown>> {
            return store.getters.reportBasics;
        },

        currentDocumentLocation() {
            return store.state.currentDocumentLocation;
        },
    },
    methods: {
        /**
         * Updates the searchQuery in Vuex state/localStorage to the currently
         * searched string in the search box.
         */
        updateSearchQuery(e: Event): void {
            this.searchQuery = (e.target as HTMLInputElement).value;
            store.dispatch("updateSearchQuery", (e.target as HTMLInputElement).value);
        },

        resetSearchBar(): void {
            //const el = JSON.parse(JSON.stringify(this.$refs["search-bar"]));
            // const el = this.$refs["search-bar"] as HTMLInputElement;
            // console.log(el);
            // el.value = "";
            this.searchQuery = "";
        },
    },

    watch: {
        currentDocumentLocation: {
            handler(current, old) {
                if (current != old) {
                    this.resetSearchBar();
                }
            },
            deep: true,
            immediate: true,
        },
    },
});
