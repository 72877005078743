import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-20f4608f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-ml-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_Tag = _resolveComponent("Tag")!

  return (_openBlock(), _createBlock(_component_Tag, {
    class: "tablet p-mr-1 p-mt-1",
    onClick: _ctx.showDetail,
    rounded: true,
    style: {"background-color":"lightgrey","color":"black"}
  }, {
    default: _withCtx(() => [
      _createVNode(_component_font_awesome_icon, {
        icon: `${_ctx.icon}`
      }, null, 8, ["icon"]),
      _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.id), 1)
    ]),
    _: 1
  }, 8, ["onClick"]))
}