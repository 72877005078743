
import { defineComponent } from "vue";

import Katex from "@/components/layout/Katex.vue";

export default defineComponent({
    components: {
        Katex,
    },

    props: {
        parameter: {
            type: Object,
            default: Object,
        },
    },

    data() {
        return {
            addInfo: {},
        };
    },
});
